import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { isUserLoggedIn } from "../../services/auth";

const Footer: React.FC<any> = (): JSX.Element => {
  return (
    <div className="portalFooter">
      <div className={`footerTop ${isUserLoggedIn() ? "" : "hidden"}`}>
        <div className="footerRow">
          <h3 className="text-center">Holistic products shortcuts</h3>

          <ul className="products">
            {/* <li className="product commingSoon text-center">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="times"
                className="svg-inline--fa fa-times fa-w-11"
                role="img"
                width=" 14"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 352 512"
              >
                <path
                  fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                ></path>
              </svg>
              <span className="block">CCS Software</span>
              <a href="javascript:void(0)" className="inline-block">
                COMING SOON
              </a>
            </li> */}

            <li className="product visit text-center">
              <FaCheck size={14} />
              <span className="block">Products & Events</span>
              <a
                href="https://holisticmanagement.net.au/resources/"
                className="inline-block"
                target="_blank"
                rel="noreferrer"
              >
                VISIT
              </a>
            </li>

            {/* <li className="product commingSoon text-center">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="times"
                className="svg-inline--fa fa-times fa-w-11"
                role="img"
                width=" 14"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 352 512"
              >
                <path
                  fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                ></path>
              </svg>
              <span className="block">HR Software</span>
              <a href="javascript:void(0)" className="inline-block">
                COMING SOON
              </a>
            </li> */}

            {/* <li className="product commingSoon text-center">
              <FaTimes size={20} />
              <span className="block">Resources</span>
              <a href="javascript:void(0)" className="inline-block">
                COMING SOON
              </a>
            </li> */}

            <li className="product subscribed active text-center">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="check"
                className="svg-inline--fa fa-check fa-w-16"
                role="img"
                width=" 14"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <span className="block">HMC Portal</span>
              <a href="#" className="inline-block">
                ACTIVE
              </a>
            </li>

            <li className="product subscribed text-center">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="check"
                className="svg-inline--fa fa-check fa-w-16"
                role="img"
                width=" 14"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <span className="block">Holistic QIP</span>
              <a
                href="https://app.holisticqip.com.au/"
                className="inline-block"
                target="_blank"
                rel="noreferrer"
              >
                VISIT
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footerTop footerTestimonial">
        <div className="footerRow">
          <h2>TESTIMONIALS</h2>
          <ul className="footerTestimonialData products">
            <li>
              <p>
                Thank you Sandra and Alina for helping our team grow. We as a
                team enjoyed Alina’s programming workshop it inspired our
                educators to create projects and guided them through the
                process. Thankyou Sandra for your guidance and mentoring support
                during my educator leader role, this certainly helped me
                achieved my goals.
              </p>
              <h5>Kariong Out of School Hour Care</h5>
            </li>
            <li>
              <p>
                Working with Holistic Management has changed the way we lead in
                our centre. Over the last 6 months, we’ve seen a huge shift in
                how we set and achieve our goals. What used to feel like
                leadership challenges now feels like opportunities for growth.
                This shift in mindset has been incredible. We’ve gone from just
                meeting expectations to exceeding them, and with clearer, more
                reflective practices in place, we’re constantly surpassing our
                development goals. Holistic Management has given us the tools to
                create a culture of reflection. Our leadership team now takes
                the time to look at what’s working and where we can improve, not
                just in our QIP, but also in how we work with educators every
                day. We’ve learned to listen better and respond more
                thoughtfully, creating an environment where feedback and
                reflection are part of our ongoing success. The strategies and
                frameworks we’ve learned from Holistic Management have helped us
                make better decisions and build a culture of trust and teamwork.
                We now approach challenges with a stronger sense of purpose,
                knowing that each choice we make impacts not only the present,
                but also the long-term benefits for our families and children.
                Thanks to Holistic Management, we are now better equipped to
                guide and inspire our educators. They have the support,
                resources, and space to grow as leaders themselves. As a team,
                we approach every challenge with a mindset of learning and
                adaptation, knowing this will help us create a stronger, more
                effective learning environment. The changes we’ve seen have been
                invaluable, and we are deeply grateful for their help in making
                us more effective and compassionate leaders.
              </p>
              <h5>Sarah -Childs World Centre 4</h5>
            </li>
            <li>
              <p>
                Mel- I can't thank you enough for all of your support, you have
                given me- valuable insight and learning that i will keep with me
                forever.
              </p>
              <h5>Little Scholars Burleigh</h5>
            </li>
            <li>
              <p>
                Thank you - Last year, when you visited the centre and helped us
                create a strategic plan to reignite our motivation, it made a
                huge difference. Without your support, we wouldn’t be where we
                are today, with the team so focused and driven to set and
                achieve goals for the new year. We truly value our mentoring
                sessions and all the resources you've provided.
              </p>
              <h5>Little Scholars Yatala</h5>
            </li>
          </ul>
        </div>
      </div>
      <div className="footerBottom">
        <div className="footerRow flex items-center justify-between">
          <div
            className={`footerMenu ${
              isUserLoggedIn() ? "visible" : "invisible"
            }`}
          >
            <ul className="flex items-center">
              <li>
                <a href="/portal/monthly-focus" className="footerMenuText">
                  Monthly Focus
                </a>
              </li>
              <li>
                <a href="/portal/nqa-documents" className="footerMenuText">
                  NQA Documents
                </a>
              </li>
              <li>
                <a href="/portal/newsletters" className="footerMenuText">
                  Newsletters
                </a>
              </li>
              <li>
                <a href="/portal/links" className="footerMenuText">
                  Links
                </a>
              </li>
              <li>
                <a href="/portal/reports/main" className="footerMenuText">
                  Reports
                </a>
              </li>
            </ul>
          </div>

          <div className="footerCopyright">
            <span className="block">
              Copyright © 2021 Holistic Management And Consultancy. All Rights
              Reserved
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
