import React, { useState } from "react";
import dashboardBanner from "../../static/images/dashboard_Banner_new.png";
import subImage from "../../static/images/dashboard1.jpg";
import subImage1 from "../../static/images/dashboard2.jpg";
import subImage2 from "../../static/images/dashboard3.jpg";
import subImage3 from "../../static/images/dashboard4.jpg";
import serviceLogo from "../../static/images/service_logo.png";
import { useHistory, Link } from "react-router-dom";
import BookAMentorSession from "../../components/Modals/BookAMentorSession";
import { FaSearch } from "react-icons/fa";
import { useStoreActions, useStoreState } from "../../hooks";
import { getUserLoggedIn, isUserLoggedIn } from "../../services/auth";

interface DashboardProps {}

export const Dashboard: React.FC<DashboardProps> = (): JSX.Element => {
  const history = useHistory();
  const [openBookSession, setOpenBookSession] = useState<boolean>(false);

  const selectedService = useStoreState(
    (state) => state.appStore.selectedService
  );

  const authUser = getUserLoggedIn();

  const services = useStoreState((state) => state.appStore.services);

  return (
    <div className="main-content">
      {openBookSession && (
        <BookAMentorSession
          isOpen={openBookSession}
          setIsOpen={setOpenBookSession}
        />
      )}
      <div className="topHeaderContainer">
        <h1 className="contentHeader">Dashboard</h1>
      </div>

      <div
        className="dashboardBanner"
        style={{ backgroundImage: `url(${dashboardBanner})` }}
      >
        <div className="dashboardContent">
          <h2 className="contentHeader">Welcome back</h2>
          <p>
            {`Your Holistic Professional Development Hub is your one-stop resource for all the things related to quality improvement and professional growth. Here, you can easily access Policies & Procedures across all 7 NQAs, download the Focus of the Month, explore PD webinars, factsheets, and a wealth of other resources to support your team's ongoing development and success. Keep your team engaged, informed, and empowered with evrything you need, all in one place.`}
          </p>
        </div>
      </div>

      <div className="innerPagesContainer">
        <div className="serviceLogo">
          <div className="helper">
            <p className="dashboardDetails">
              Make your Holistic Portal dashboard feel like home!
            </p>
            <Link to="/edit-service-details" className="blueBtn">
              Personalise your dashboard
            </Link>
          </div>
        </div>

        <div className="searchContainer">
          <div className="search flex items-center justify-between">
            <input
              type="text"
              name="search"
              id="s"
              placeholder="Search Management Portal"
            />
            <button type="submit" className="flex items-center justify-center">
              <FaSearch />
            </button>
          </div>
        </div>

        <div className="innerPagesList">
          <div className="innerPageGallery">
            <img src={subImage} />
            <div className="innerPageSubGallery">
              <img src={subImage1} />
              <div>
                <img src={subImage2} alt="" />
                <img src={subImage3} alt="" />
              </div>
            </div>
          </div>
          <ul>
            <li>
              <a
                href="https://holisticmanagementhub.com.au/user-accounts"
                target="_blank"
              >
                <div className="dashboardIcon">
                  <span>
                    <svg
                      height="1em"
                      width="1em"
                      font-size="20"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="users"
                      className="svg-inline--fa fa-users fa-w-20"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                    >
                      <path
                        fill="currentColor"
                        d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"
                      ></path>
                    </svg>
                  </span>
                </div>
                <span className="dashboardName">Users</span>
                <span className="dashboardDetails">Add your team</span>
              </a>
            </li>
            {/* <li>
              <a
                href="https://dev.holisticmanagementhub.com.au/Account/UserAccounts.php?tab=account"
                target="_blank"
              >
                <div className="dashboardIcon">
                  <span>
                    <svg
                      height="1em"
                      width="1em"
                      font-size="20"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="cog"
                      className="svg-inline--fa fa-cog fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                      ></path>
                    </svg>
                  </span>
                </div>
                <span className="dashboardName">Account</span>
                <span className="dashboardDetails">Manage subscription</span>
              </a>
            </li>*/}
            <li
              onClick={() => {
                history.push(`/nqa-documents`);
              }}
            >
              <a>
                <div className="dashboardIcon">
                  <span>
                    <svg
                      height="1em"
                      width="1em"
                      font-size="20"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="file-alt"
                      className="svg-inline--fa fa-file-alt fa-w-12"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        fill="currentColor"
                        d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 236c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-64c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-72v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm96-114.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"
                      ></path>
                    </svg>
                  </span>
                </div>
                <span className="dashboardName">NQA documents</span>
                <span className="dashboardDetails">
                  Policies and Procedures, Tools & Templates
                </span>
              </a>
            </li>
            {/*<li>
              <a
                href="https://dev.holisticmanagementhub.com.au/Messages/Messages.php"
                target="_blank"
              >
                <div className="dashboardIcon">
                  <span>
                    <svg
                      height="1em"
                      width="1em"
                      font-size="20"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="envelope"
                      className="svg-inline--fa fa-envelope fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                      ></path>
                    </svg>
                  </span>
                </div>
                <span className="dashboardName">Messages</span>
                <span className="dashboardDetails">View all</span>
              </a>
			</li>*/}
            <li
              onClick={() => {
                history.push(`/monthly-focus`);
              }}
            >
              <a>
                <div className="dashboardIcon">
                  <span>
                    <svg
                      height="1em"
                      width="1em"
                      font-size="20"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="bullseye"
                      className="svg-inline--fa fa-bullseye fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 496 512"
                    >
                      <path
                        fill="currentColor"
                        d="M248 8C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248S384.97 8 248 8zm0 432c-101.69 0-184-82.29-184-184 0-101.69 82.29-184 184-184 101.69 0 184 82.29 184 184 0 101.69-82.29 184-184 184zm0-312c-70.69 0-128 57.31-128 128s57.31 128 128 128 128-57.31 128-128-57.31-128-128-128zm0 192c-35.29 0-64-28.71-64-64s28.71-64 64-64 64 28.71 64 64-28.71 64-64 64z"
                      ></path>
                    </svg>
                  </span>
                </div>
                <span className="dashboardName">FOM</span>
                <span className="dashboardDetails">Monthly Checklists</span>
              </a>
            </li>
            {isUserLoggedIn() &&
              services.some(
                (service) =>
                  service.product?.toLocaleLowerCase().includes("trial") ||
                  service.product?.toLocaleLowerCase().includes("mentoring")
              ) &&
              authUser.user_type.toLocaleLowerCase() !== "user" && (
                <li onClick={() => setOpenBookSession(true)}>
                  <a>
                    <div className="dashboardIcon">
                      <span>
                        <svg
                          height="1em"
                          width="1em"
                          font-size="20"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="calendar-alt"
                          className="svg-inline--fa fa-calendar-alt fa-w-14"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path
                            fill="currentColor"
                            d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"
                          ></path>
                        </svg>
                      </span>
                    </div>
                    <span className="dashboardName">Mentoring</span>
                    <span className="dashboardDetails">Book a session</span>
                  </a>
                </li>
              )}
            {/* <li
              onClick={() => {
                history.push(`/edit-service-details`);
              }}
            >
              <a>
                <div className="dashboardIcon">
                  <span>
                    <svg
                      height="1em"
                      width="1em"
                      font-size="20"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="building"
                      className="svg-inline--fa fa-building fa-w-14"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentColor"
                        d="M436 480h-20V24c0-13.255-10.745-24-24-24H56C42.745 0 32 10.745 32 24v456H12c-6.627 0-12 5.373-12 12v20h448v-20c0-6.627-5.373-12-12-12zM128 76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76zm0 96c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40zm52 148h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12zm76 160h-64v-84c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v84zm64-172c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40z"
                      ></path>
                    </svg>
                  </span>
                </div>
                <span className="dashboardName">Service details</span>
                <span className="dashboardDetails">View & edit</span>
              </a>
            </li> */}
            <li>
              <a
                href="https://holisticmanagement.net.au/get-help/"
                target="_blank"
              >
                <div className="dashboardIcon">
                  <span>
                    <svg
                      height="1em"
                      width="1em"
                      font-size="20"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="heart"
                      className="svg-inline--fa fa-heart fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"
                      ></path>
                    </svg>
                  </span>
                </div>
                <span className="dashboardName">Help</span>
                <span className="dashboardDetails">We can help you</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
